.diagramm_abschlag {
  width:194px;
  height:194px;
  overflow:hidden;
  margin: 0 auto 2rem;
  position: relative;
  .entry_middle {
    width:100%;
    height:100%;
    position:absolute;
    left:50%;
    top:50%;
    transform:translate(-50%,-50%);
    z-index:10;
    display:flex;
    justify-content:center;
    align-items:center;
    flex-wrap:wrap;
    p {
      margin:0;
      text-align: center;
    }
  }
}