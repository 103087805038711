.card-holder {
	display: flex;
	flex-direction: column;
	height: 100%;
}

.card {
	background: $white;
	border-radius: .5rem;
	display: flex;
	flex: 1 1 auto;
	flex-direction: column;
	overflow: hidden;
	&.card-marginal {
		height:100%
	}
	.card-header {
		padding: 1.5rem 2rem 0;

		h3 {
			margin-bottom: 0;
		}
	}
	.card-image {
		img {
			width:100%;
			height: auto;
		}
	}

	form {
		position: relative;
		button.card-button {
			position: relative;
			width: 100%;
		}
		padding-bottom: 50px;
	}

	button.card-button {
		border: none;
		border-top: 1px solid #dadada;
		width: 100%;
	}

	.card-body {
		flex: 1 1 auto;
		padding: 1rem 1rem 1.5rem;

		.widget_image {
			height: 250px;
			width: 100%;
			background-position: center center;
			background-repeat: no-repeat;
			background-color: transparent;
			background-size: cover;
			margin-bottom: 25px;
		}
		p {
			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	&.main-card {
		height: 100%;
		.card-body {
			padding: 2rem;
			.mobile-card {
				@include media-breakpoint-up(md) {
					.card-body {
						padding: 0;
					}
				}
			}
		}
	}
	@include media-breakpoint-down(lg) {
		&.card-marginal {
			margin-top: 2.5rem;
		}
	}
	@include media-breakpoint-up(md) {
		.card-body {
			padding: 1.5rem 2rem 2rem;
		}
	}

}
