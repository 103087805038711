.filter {
  .filter-content {
    .fas {
      padding-left: 20px;
      padding-right: 20px;
      &:before {
        font-size: 20px;
        color: black;
      }
    }
    display: block;
    background: #f1f0f5;
    padding: 10px;
    margin-bottom: 3px;
    .styled-text {
      display: inline;
      width: 20%;
      right: 0;
    }

    br {
      display: none;
    }
  }
}

.search {
  width: 600px;
}

.searchH {
  position: relative;
  width: 70%;
  .fa-search {
    position: absolute;
    right: 9px;
    font-size: 25px;
    top: 38px;
  }
}


#search {
  border: 1px solid #ccc;
  border-radius: 4px;
  height: 43px;
  width: 100%;
}

#search[type=text] {
  padding-left: 2%;
}

.search-holder fieldset {
  width: 611px;
  margin-left: 20px;
}