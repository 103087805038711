.termine {
  .termin {
    float: left;
    height: 98px;
    width: 47%;
    @include tab-max {
      width: 100%;
    }

    background: white;
    margin-bottom: 40px;
    &:nth-child(even) {
      margin-left: 40px;
      @include tab-max {
        margin-left: 0px;
      }
    }

    .icon {
      display: table-cell;
      background: #00A7E7;
      width: 80px;
      height: 98px;
      position: relative;
      .svg {
        width: 100%;
        height: 100%;
        svg {
          width: 70%;
          height: 100%;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
    .body {
      display: table-cell;
      vertical-align: middle;
      width: 84%;
      div {
        display: table;
        width: 100%;
        div {
          display: table-cell;
          width: 50%;
          @include small-max {
            display: block;
            width: 100%;
            float: none;
            clear: left;
            &:last-child {
              margin-left: 40px;
            }
            h2 {
              margin-bottom: 5px;
            }
          }
          h2 {
            padding-bottom: 0;
            font-size: 20px;
            float: left;
            margin-left: 40px;
            line-height: 1;
          }
          &:last-child {
            line-height: 1;
            vertical-align: bottom;
          }
        }
      }
    }
  }
}