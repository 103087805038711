
.form input[type=checkbox]:checked + label::before {
  font-family: "FontAwesome";
  display: block;
  content: "\f00c";
  font-size: 1em;
  vertical-align: middle;
  text-align: center;
  padding: 5px 0;
}

.form .checker-label {
  display: block;
  padding: 5px 0;
  float: left;
  font-weight: normal;
  margin-left: 0;
  padding-left: 40px;
}

.form .checker-label::before {
  content: '';
  overflow: hidden;
  width: 30px;
  height: 30px;
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 5px;
  background-color: #ffffff;
  background: -webkit-linear-gradient(90deg, #dbdbdb 0%, #ffffff 55%);
  background: linear-gradient(0deg, #dbdbdb 0%, #ffffff 55%);
  border: 1px solid #cccccc;
}


.slideBox {
  position: relative;
  input[type="checkbox"] {
    position: absolute;
    cursor: pointer;
    width: 100%;
    z-index: 11;
    &:checked {
      & + {
        label {
          &:after {
            margin-left: 16px;
            content: "";
          }
          &:before {
            background: #ff8d2f;
            content: "";
          }
        }
      }
    }

    & + label {
      cursor: pointer;
      position: relative;
      padding: 1px 0 0 50px;
      &:before {
        content: "";
        position: absolute;
        display: block;
        left: 0;
        top: 0;
        width: 40px; /* x*5 */
        height: 24px; /* x*3 */
        border-radius: 16px; /* x*2 */
        background: #fff;
        border: 1px solid #d9d9d9;
        transition: all 0.3s;
      }
      &:after {
        content: "";
        position: absolute;
        display: block;
        left: 0px;
        top: 0px;
        width: 24px; /* x*3 */
        height: 24px; /* x*3 */
        border-radius: 16px; /* x*2 */
        background: #fff;
        border: 1px solid #d9d9d9;
        transition: all 0.3s;
      }

      &:hover {
        &:after {
          box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
        }
      }
    }
  }

}
