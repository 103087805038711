.card-aktuelles {
	.img-holder {
		position: relative;
		padding-bottom: 50%; /* ratio 16x9 */
		height: 0;
		overflow: hidden;
		width: 100%;
		height: auto;
		img {
			position:absolute;
			left:0;
			top:50%;
			width:100%;
			height:auto;
			transform:translate(0,-50%);
		}
	}
	.carousel {
		.carousel-caption {
			position:relative;
			right:auto;
			left:auto;
			bottom:auto;
			text-align: left;
			padding: 1.5rem 2rem 1.5rem;
			.date {
				color:$gray-600;
				margin-bottom: .25rem;
			}
			.h2 {
				color:$black;
				padding-bottom:0;
			}

		}
		.carousel-controls {
			position:relative;
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 0 2rem 2rem;
			.carousel-indicators, .carousel-buttons {
				position:relative;
				margin: 0;
			}
			.carousel-indicators {
				button {
					width:.875rem;
					height: .875rem;
					border:2px solid $black;
					border-radius: 100%;
					opacity: 1;
					margin: 0 .75rem 0 0;
					box-sizing: border-box;
					outline: none;
					&.active {
						background: $black;
					}
				}
			}
			.carousel-buttons {
				display:flex;
				.carousel-control-prev, .carousel-control-next {
					position: relative;
					width:auto;
					opacity:1;

				}
				.carousel-control-prev {
					margin-right: 1rem;
					span {
						transform: rotate(180deg);
					}
				}
			}
		}
	}
	@include media-breakpoint-down(md) {
		background:none;
		.carousel {
			.carousel-inner {
				border-radius: 0 0 8px 8px;
				margin-bottom: 1rem;
				.carousel-item {
					background: $white;
				}
				.carousel-caption {
					padding: 1rem 1rem 1.5rem;
				}
			}

			.carousel-controls {
				justify-content: center;

				.carousel-buttons {
					display: none;
				}
			}
		}
	}
}