.form-wrapper {
	padding:0;
	&:last-child {
		margin-bottom:0;
	}
}
.form-group {
	padding-bottom: 1rem;
	&.last {
		padding-bottom: 0;
	}
	&.wrapper-group {
		padding-bottom:0;
	}
	&.high {
		padding-bottom:1.5rem;
	}
	&.higher {
		padding-bottom:2rem;
	}
	.form-control {
		height:3rem;
		background:$lightgrey;
		border:1px solid $input;
		border-radius: 8px;
		padding:0 1rem;
		width:100%;
	}
	label {
		@include copy-small-light;
		color:$black;
		&.tooltip {
			display:flex;
			justify-content: flex-start;
			align-items: center;
			a {
				display:inline-flex;
				margin-top:-.125rem;
			}
			.icon {
				margin-left: .25rem;
			}
		}
	}
	.form-radio, .form-check {
		position: relative;
		label {
			@include copy;
		}
		&:last-child {
			label {
				margin-bottom: 0;
			}
		}
	}
	.date, .counter {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		input {
			margin-right: 1rem;
		}
		&.collapse {
			&:not(.show) {
				display:none;
			}

		}
	}
	.counter {
		height: 3rem;
		.meter-reading-round-after {
			width:3rem;
			height: 3rem;
			display:inline-flex;
			align-items: flex-end;
			flex:0 0 auto;
			padding:0;
		}
	}
	.tooltip-text {
		@include copy-small-light;
		color:$cta;
		margin-top: .5rem;
		display:inline-block;
	}
}


label.radio-label, label.check-label {
	margin-bottom: 0;
	position: relative;
	padding-left: 3rem;
	color:$black;
	font-weight: 400;
	&:after, &:before {
		content:""!important;
		display:block;
		position:absolute;
		border-radius:50%;
		transition: all .3s ease;
	}
	&:after {
		width:2rem;
		height:2rem;
		border: 1px solid $input;
		left:0;
		right:auto;
		top:0;
	}
	&:before {
		width:1rem;
		height:1rem;
		left:.5rem;
		right:auto;
		top:.5rem;
		background-color: transparent;
	}
	a {
		color:$cta;
	}
}

input {
	&:checked {
		& + label.radio-label {
			border-color: $cta;
			&:after {
				border-color:$cta;
			}
			&:before {
				background-color: $cta;
			}
		}
		& + label.check-label {
			&:before {
				background-image: url("data:image/svg+xml,%0A%3Csvg width='16' height='16' viewBox='0 0 16 16' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2 8.5 5.5 12 14 3.5' stroke='%23F18700' stroke-width='2.8' fill='none' fill-rule='evenodd' stroke-linecap='square'/%3E%3C/svg%3E%0A");
				background-size: 1rem;
				background-position: center;
				background-repeat: no-repeat;
			}
		}
	}
}
.form-radio {
	label.radio-label {
		display:flex;
		align-items: center;
		min-height: 2rem;
		margin-bottom:1rem;
	}
	&:last-child {
		label.radio-label {
			margin-bottom:0;
		}
	}
}
.form-check {
	label.check-label {
		display:flex;
		align-items: center;
		min-height: 2rem;
		margin-bottom:1rem;
		&:after {
			border-radius:.5rem;
		}
	}
}