.main-nav {
    width: 100%;
    margin-bottom: 30px;
    display: table;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.3);
    @include small-max {
        padding-left: 0;

    }
}
nav {
    padding: 0;
    position: relative;
    z-index: 700;

    .nav-button {
        cursor: pointer;
        position: absolute;
        top: 3px;
        left: 3px;
        opacity: 0.0;

        @include tab-min {
            z-index: -1;
        }
        width: 40px;
        height: 40px;
        &:checked ~ .nav-inner {
            max-height: 2000px;
            opacity: 1.0;
            height: 100%;
            width: 100%;
        }
        &:checked ~ .nav-trigger {
            background: #aaaaaa;
            .nav-icon::before {
                content: "\f00d";
            }
        }
    }
    .nav-trigger {
        padding: 16px 10px 17px 20px;
        transition: all 0.6s ease-in-out;
        text-transform: uppercase;
        font-weight: 600;
        @include tab-min {
            display: none;
        }
        .nav-icon {
            margin-right: 10px;
        }
    }
    .nav-inner {
        //overflow: hidden;
        @include mid-max {
            transition: max-height 2.0s;
            max-height: 0;
            overflow: auto;
        }

        ul {
            list-style-type: none;
            padding: 0;
            margin: 0;

            li {
                position: relative;
                a {
                    text-transform: uppercase;
                    @include tab-min {

                    }
                }
                @include tab-min {
                    float: left;
                    margin-right: 44px;
                    font-size: 16px;
                }
                @include mid-max {
                    position: relative;
                    border-bottom: 1px #aaaaaa solid;
                    &.hasSubmenu {
                        &> a::after {
                            position: absolute;
                            right: 10px;
                            top: 17px;
                            content: "\f067";
                            font-family: "FontAwesome";
                        }
                    }
                }

                &:hover ul {
                    opacity: 1.0;
                    z-index: 1;
                    height: auto;
                    &.level-2 {
                        overflow: visible;
                        &:before {
                            @include tab-min {
                                opacity: 1;
                            }
                        }
                    }
                }
                ul {
                    @include tab-min {
                        height: 0px;
                        left: 50%;
                        transform: translateX(-50%);
                    }
                    padding: 0 20px;
                    @include mid-max {
                        padding: 0;
                    }
                    li {
                        a {
                            text-transform: none;
                        }
                    }
                    &.level-2 {
                        overflow: auto;
                        &:before {
                            opacity: 0;
                            top: -14px;
                            content: "";
                            width: 0;
                            height: 0;
                            border-left: 12px solid transparent;
                            border-right: 12px solid transparent;
                            border-bottom: 14px solid;
                            border-bottom-color: inherit;
                            position: absolute;
                            left: 50%;
                            margin-left: -10px;
                            z-index: 95;
                            -webkit-transition: all 0.5s ease-out 0.25s;
                            -moz-transition: all 0.5s ease-out 0.25s;
                            transition: all 0.5s ease-out 0.25s;
                        }
                        @include mid-max {
                            display:none;
                        }
                    }
                }
                a {
                    transition: all 0.6s ease-in-out;
                    @include mid-max {
                        padding: 16px 20px;
                        display: block;
                    }
                }

                .sub-button {
                    position: absolute;
                    height: 45px;
                    cursor: pointer;
                    width: 100%;
                    z-index: 100;
                    opacity: 0.0;
                    top: 0;
                    left: 0;
                    @include tab-min {
                        display: none;
                    }
                    &:checked ~ ul {
                        display: block;
                    }
                }
            }
        }
    }
}

@include mid-max {
    .nav.nav_secondary.nav_top {
        display:none;
    }
}

.not-logged-in nav {
    display: none;
}
@include tab-min {
    nav {
        ul {
            li {
                &:hover {
                    a {
                        //background: white;
                        //color: $nav-hover-color;
                    }
                }
                a {
                    padding: 16px 15px;
                    display: block;
                    //z-index: 770;
                    position: relative;
                }
                ul {
                    height: 0px;
                    transition: opacity 0.6s ease-in-out;
                    opacity: 0.0;
                    position: absolute;
                    width: 250px;
                    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.4);
                    z-index: -1;
                    li {
                        padding: 10px 10px 10px 10px;
                        position: relative;
                        margin-left: -15px;
                        width: 100%;
                        a {
                            padding:0;
                            font-size: 16px;
                            text-transform: none;
                        }
                    }
                }
            }
            .sublist {
                display: none;
            }
        }
    }

    .nav_secondary.nav_top {
        float: right;
        ul {
            list-style: none;
            padding: 0;
            margin: 0;
            li {
                font-size: 13px;
                line-height: 15px;
                float: left;
            }
        }
    }
}

.nav_secondary .nav-item {
    a {
        padding: 0 8px;
        border-left: 1px solid;
    }

    &:first-child a {
        border-left: 0;
    }
}