.processes {
  .process {
    height: 98px;
    width: 47%;
    @include tab-max {
      width: 100%;
    }

    background: white;
    margin-bottom: 40px;
    display: table;
    .icon {
      display: table-cell;
      background: #00A7E7;
      width: 80px;
      position: relative;
      .svg {
        width: 100%;
        height: 100%;
        svg {
          width: 70%;
          height: 100%;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }

    .processList {
      display: table;
      .item {
        .circle_ckecked {
          fill: #1b9fb4;
        }
        display: table-row;
        & > div {
          display: table-cell;
          .svg {
            width: 100%;
            height: 100%;
            svg {
              width: 20px;
              height: 20px;
              position: relative;
              top: 4px;
              left: 1px;

            }
          }
        }
      }
    }
  }
}