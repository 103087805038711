#vertragswahl {
  .ui-accordion-header {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .ui-accordion-header-icon {
      display: none;
    }
    div {
      padding-left: 5px;
      padding-right: 5px;
      &:nth-child(4) {
        padding-left: 0;
      }
    }
  }


}

/*
#vertragswahl {
  h3 {
    & > div {
      margin-left: 20px;
      display: inline-block;
      vertical-align: middle;

      @include tab-max {
        &:nth-child(5) {
          margin-left: 55px;
        }
      }
      @include small-max {
        &:nth-child(4) {
          margin-left: 55px;
        }
      }
    }
  }

  .ui-accordion-header-icon {
    display: none;
  }


  .icon {
    margin-left: 0;
  }
}
 */


.debAuswahl {
  position: relative;
  height: 40px;
  background: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: auto;
  display: inline-block;
  padding-left: 10px;
  font-size: 0;
  div:first-child {
    font-size: 15px;
    width: 100px;
    height: 100%;
    display: inline-block;
    padding:0;
    border: 0;
  }
  input[type='text'] {
    font-size: 15px;
    height: 100%;
    width: 30px;
    display: inline-block;
    padding:0;
    border: 0;
    &:focus {
      border: 0 !important;
    }
  }
  button {
    font-size: 15px;
    height: 100%;
    padding:0;
    border-top: 0;
    border-bottom: 0;
    border-right: 0;
    border-left: 1px solid #ccc;
    border-bottom-left-radius: 5px;
    width: 30px;
    position: relative;
    display: inline-block;
  }
}