.card-button {
	padding: 1.5rem 2rem;
	font-weight: 700;
	border-top: 1px solid $border;
	display: flex;
	justify-content: space-between;
	align-items: center;

	i {
		width: 1.5rem;
		height: 1.5rem;
		background-image: url("data:image/svg+xml,%0A%3Csvg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m12 4-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z' fill='%231A1A1A' fill-rule='nonzero'/%3E%3C/svg%3E%0A");
		background-repeat: no-repeat;
		background-size: 1.5rem;
		background-position: center;
	}
}

.button-row {
	&.-align-center {
		a {
			&.forward {
				div {
					&:first-child {
						margin-left: auto;
					}
					&:last-child {
						margin-right: auto;
					}
				}
			}
		}
	}
}

.icon-button {
	width: 3rem;
	height: 3rem;
	border-radius: 8px;
	display: flex;
	flex:0 0 auto;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	&.fa-calendar {
		padding: 0;
	}
	&.cta {
		background: $cta;
	}
}

.button-wrapper-lieferstellen-v2 {
	display: flex;
	justify-content: space-between;
}

.button {
	padding: .70rem 1.5rem;
	display: inline-flex;
	align-items: center;
	border-radius: 8px;
	&.button-secondary, &.button-primary {
		display: block;
	}
}

.twigButton {
	display: flex;
	align-content: space-between;
	align-items: center;
	width: auto;
	font-weight: 700;
	padding: .4rem 1.5rem;
	&.disabled {
		border-color: #d0d0d0;
		color: #d0d0d0;
		cursor: default;
		opacity: 0.6;
	}
	div {
		display: flex;
		align-items: center;
	}
	&.back {
		div {
			&:last-child {
				padding-left: 8px;
			}
		}
	}

	&.forward {
		div {
			&:first-child {
				padding-right: 8px;
			}
		}
	}

}

.btn {
	&.btn-back {
		i {
			content:"";
			display:block;
			width:1.5rem;
			height: 1.5rem;
			background-image: url("data:image/svg+xml,%0A%3Csvg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m12 4 1.41 1.41L7.83 11H20v2H7.83l5.58 5.59L12 20l-8-8z' fill='%23FFF' fill-rule='nonzero'/%3E%3C/svg%3E%0A");
			background-size: 1.5rem;
			background-position: center;
			background-repeat: no-repeat;
			margin-right: .5rem;
		}
	}
    padding:.75rem 1.5rem;
    display:inline-flex;
	align-items: center;
	border:none;
	border-radius: 8px;
	&.btn-back {
		i {
			margin-right:.5rem;
			margin-left: 0;
			background-image: url("data:image/svg+xml,%0A%3Csvg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m12 4 1.41 1.41L7.83 11H20v2H7.83l5.58 5.59L12 20l-8-8z' fill='%23FFF' fill-rule='nonzero'/%3E%3C/svg%3E%0A");
		}
	}
    &.btn-cta {
        background:$cta;
		color:$white;
		font-weight: 700;
		i {
			width: 1.5rem;
			height: 1.5rem;
			background-image: url("data:image/svg+xml,%0A%3Csvg width='24' height='24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 4l-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z' fill='%23ffffff'/%3E%3C/svg%3E");
			background-repeat: no-repeat;
			background-size: 1.5rem;
			background-position: center;
			margin-left:.5rem;
		}
		&.btn-back {
			i {
				margin-right:.5rem;
				margin-left: 0;
				background-image: url("data:image/svg+xml,%0A%3Csvg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m12 4 1.41 1.41L7.83 11H20v2H7.83l5.58 5.59L12 20l-8-8z' fill='%23FFF' fill-rule='nonzero'/%3E%3C/svg%3E%0A");
			}
		}
		&.btn-download {
			i {
				margin-left:.75rem;
				background-image: url("data:image/svg+xml,%0A%3Csvg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M18 15v3H6v-3H4v3c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2v-3h-2zm-1-4-1.41-1.41L13 12.17V4h-2v8.17L8.41 9.59 7 11l5 5 5-5z' fill='%23FFF' fill-rule='nonzero'/%3E%3C/svg%3E%0A");
			}
		}
		&.btn-logout {
			i {
				margin-left:.75rem;
				background-image: url("data:image/svg+xml,%0A%3Csvg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13 3h-2v10h2V3zm4.83 2.17-1.42 1.42A6.92 6.92 0 0 1 19 12c0 3.87-3.13 7-7 7A6.995 6.995 0 0 1 7.58 6.58L6.17 5.17A8.932 8.932 0 0 0 3 12a9 9 0 0 0 18 0c0-2.74-1.23-5.18-3.17-6.83z' fill='%23FFF' fill-rule='nonzero'/%3E%3C/svg%3E%0A");
			}
		}
    }
}