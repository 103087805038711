
// Media Queries
// -----------------------------------------------------------------------------

$small-min: 320px;
$small-max: 768px;

$mid-min: 769px;
$mid-max: 1023px;

$tab-min: 1024px;
$tab-max: 1280px;

$desk-min: 1280px;


@mixin small-min {
  @media (min-width: #{$small-min}) {
    @content;
  }
}

@mixin small-max {
  @media (max-width: #{$small-max}) {
    @content;
  }
}

@mixin mid-min {
  @media (min-width: #{$mid-min}) {
    @content;
  }
}

@mixin mid-max {
  @media (max-width: #{$mid-max}) {
    @content;
  }
}

@mixin tab-min {
  @media (min-width: #{$tab-min}) {
    @content;
  }
}

@mixin tab-max {
  @media (max-width: #{$tab-max}) {
    @content;
  }
}

@mixin desk-min {
  @media (min-width: #{$desk-min}) {
    @content;
  }
}

