table.account {
  width:100%;
  border-collapse:collapse;
}
table.account td,
table.account th {
  text-align:left;
  padding:16px 15px;
  color:#787878;
  border-bottom:1px solid;
  vertical-align:top;
}
table.account td {
  font-family: 'Roboto';
  font-size:14px;
}
table.account td.demand {
  color:#ca0d1a;
}
table.account td.credit {
  color:#6bc04b;
}
table.account th {
  padding-top:36px;
  color:black;
  font-size:16px;
  font-weight:normal;
}
table.account td.right,
table.account th.right {
  text-align:right;
}
table.account tfoot td {
  border:none;
  padding:20px 0 40px;
}
table.account tfoot td a.button {
  display:inline-block;
  width:auto;
}
table.zebra tbody tr:nth-child(2n) td {
  background-color:#D0D0D0 !important;
}

@media all and (max-width: 1060px) {
  table.account tfoot td a.button.show-desktop {
    display:none;
  }
}
@media all and (max-width: 768px) {
  table.account th {
    color:#f39100;
  }
  table.account thead {
    display:none;
  }
  table.account td {
    border:none;

  }
  table.account td {
    display:block;
    padding:5px 20px 5px 50%;
    position:relative;
  }
  table.account td:first-child {
    border-top:1px solid #c9e9ed;
    padding-top:25px;
  }
  table.account td:last-child {
    padding-bottom:25px;
  }
  table.account td:before {
    content: attr(data-label);
    position:absolute;
    left:0;
  }
  table.account td.right {
    text-align:left;
    padding-left: 170px;
  }
  .new-content-wrapper {
    margin-left:20px;
  }

}

.saldoGreen {
  color: green !important;
  td {
    color: green !important;
  }
}

.saldoRed {
  color: red !important;
  td {
    color: red !important;
  }
}