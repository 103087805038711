.energies {
  @media (max-width: 768px) {
    position: absolute;
    right: 20px;
    .table-wrapper td.input-inline-wrapper div:first-child {
      margin-right: 35px;
    }
  }
  .label {
    display: flex;
    align-items: center;
    height: 100%;
    &:first-child {
      width: 100%;
      cursor: pointer;
    }
    label {
      margin: auto;
    }
    .icon {
      margin-right: 10px;
      &.Zusatzoptionen {
        margin-left: -34px;
      }
    }
  }
  & > div {
    &.isChecked {
      background: #f1f0f5;
    }
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    justify-content: space-between;
    height: 56px;
    padding: 0 8px 0 30px;
  }
}

.abschlag {
  @media (max-width: 768px) {
    .table-wrapper td.input-inline-wrapper div:first-child {
      margin-left: 12px;
    }
  }
  .table-wrapper .table-head td {
    font-size: 14px;
  }
}