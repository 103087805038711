.card-quicklinks {
	@include media-breakpoint-down(lg) {
		.card-body {
			padding-bottom: 1rem;
		}
		ul.quicklinks {
			overflow:hidden;

		}
	}
	@include media-breakpoint-up(lg) {
		.carousel-item {
			margin: 0!important;
			float:none;
			display:block!important;
			transform: none!important;
		}
	}
}