@mixin copy {
  font-size:1rem;
  line-height: 1.5rem;
}
p {
  @include copy;
}
.copy-small {
  font-size:0.875rem;
  line-height: 1.125rem;
  font-weight:700;
}
@mixin copy-small-light {
  font-size:0.875rem;
  line-height: 1.125rem;
  font-weight:400;
}
.copy-small-light {
  @include copy-small-light;
}
h1, .h1 {
  font-size: 2rem;
  line-height: 2.5rem;
  text-transform: none;
  &.noborder {
    border-bottom: none;
  }
}
h2, .h2 {
  font-size:1.5rem;
  line-height: 2rem;
}
@mixin h3 {
  font-size: 1.125rem;
  line-height: 1.5rem;
  margin:0 0 1.2rem;
  font-weight: bold;
  &.low {
    margin-bottom: .5rem;
  }
}
h3, .h3 {
  @include h3;
  p {
    font-size: 1.125rem;
    line-height: 1.5rem;
    margin:0;
    font-weight: bold;
  }
  &.plain {
    margin-bottom: .5rem;
  }
}
h4, .h4, h4 p, .h4 p {
  font-size: 1.125rem;
  line-height: 1.5rem;
  margin:0 0 1rem;
  font-weight:400;
}
@include media-breakpoint-up(md) {
  h1, .h1 {
    font-size: 1.5rem;
    line-height: 1.875rem;
  }
  h2, .h2 {
    font-size:1.3rem;
    line-height: 1.5rem;
  }

  h3, .h3, h3 p, .h3 p {
    font-size: 1.1rem;
    line-height: 1.2rem;
    font-weight: 700;
  }
}
