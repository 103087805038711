.progressBS {
	background:$white;
	padding: .75rem;
	border-radius: .5rem;
	.progress-list {
		border:none;
		height: auto;
		.progress-item {
			@include h3;
			padding-top:0;
			padding-bottom: 0;
			margin-bottom: 0;
			color:$black;
			min-height: 1.5rem;
			background-image: url("data:image/svg+xml,%0A%3Csvg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m12 4-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z' fill='%231A1A1A' fill-rule='nonzero' opacity='.5'/%3E%3C/svg%3E%0A");
			background-position: right center;
			background-repeat: no-repeat;
			background-size: 1.5rem;
			span {
				color:$black;
			}
			&.selected {
				color:$cta;
				span {
					color:$cta;
				}
			}
			&.done {
				background-color:transparent;
				background-image: url("data:image/svg+xml,%0A%3Csvg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m12 4-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z' fill='%23F18700' fill-rule='nonzero' opacity='.5'/%3E%3C/svg%3E%0A");
				color:$cta;
				span, &:before {
					background-color:transparent;
					color:$cta;
				}
				a {
					color:$cta;
				}
			}
			&:last-child {
				background: none;
			}
		}
	}
}