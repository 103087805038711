.loader {
  position: absolute;
  right: 15px;
  display: none;
  top: 15px;
  z-index: 10;
  div {
    height: 30px;
    width: 10px;
    display: inline-block;
    background: #cccccc;
    border: 1px solid #4b4b4b;
    animation: loader_loader 1.3s linear infinite;
    transform: scale(0.91);
    &:nth-child(1) {
      -webkit-animation-delay: 0.39s;
      animation-delay: 0.39s;
    }
    &:nth-child(2) {
      -webkit-animation-delay: 0.52s;
      animation-delay: 0.52s;
    }
    &:nth-child(3) {
      -webkit-animation-delay: 0.65s;
      animation-delay: 0.65s;
    }
  }
}

.modal .loader {
  right: 35px; }



@-webkit-keyframes loader_loader {
  0% {
    -webkit-transform: scale(1.2);
    opacity: 1; }
  100% {
    -webkit-transform: scale(0.7);
    opacity: 0.1; } }

@keyframes loader_loader {
  0% {
    transform: scale(1.2);
    opacity: 1; }
  100% {
    transform: scale(0.7);
    opacity: 0.1; } }