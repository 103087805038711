.register {
  position: relative;
  width: 50%;
  left: 50%;
  top: 20px;
  transform: translateX(-50%);
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.15);
  background: rgba(255, 255, 255, 0.8);
  padding: 15px;
  @include tab-max {
    width: 90%;
  }
  @include small-max {
    width: 100%;
    padding: 0;
    left: 0;
    transform: translateX(0);
    padding: 10px;
  }

  .form-error {
    padding-left: 0;
    padding-right: 0;
    .form-error-item {
      color: #cf3f17;
      background: none;
      padding: 0;
      margin: 0;
    }
  }
}