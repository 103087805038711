.tracks {
  img {
    width:30px;
    height: 30px;
    position: relative;
  }
  .td {
    vertical-align: middle;
    position: relative;
  }
  .tr {
    .td {
      &:nth-child(1) {
        width: 0px;
        vertical-align: top;
        text-align: right;
        color: black;
        font-weight: bold;
      }
      &:nth-child(2) {
        width: 30px;
        vertical-align: top;
        overflow:hidden;
      }
    }
    &:last-child {
      .td {
        .line {
          height: 20px;
          width: 14px;
        }
      }
    }
    &:nth-child(1) {
      .td {
        .line {
          top: 7px;
        }
      }
    }
  }
  .line {
    position:absolute;
    width: 14px;
    margin-left: 8px;

    background:#b9b9b9;
    height:1000px;
    z-index: 0;
    top:0;
    bottom:0;
  }
  p {
    line-height: 1;
    float: none;
    margin: 0;
    padding-bottom: 20px;
  }
  .headline {
    padding-bottom: 5px;
    border-left-width: 0px;
    color: black;
    font-weight: bold;
    font-size: 18px;
  }
}