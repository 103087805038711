article.infobox {
  section {
    display: block;
    top: 100px;
    .body, .body_pass {
      position: absolute;
      top: 0;
      display: block;
      background:none;
      opacity: 0.0;
      transition:opacity 500ms ease-in-out;
      z-index: -1;
      min-height: 370px;
      width: 100%;
      .pass {
        margin-top: 20px;
        text-decoration: none;
        color: #525294;
        display: block;
      }
    }
    h2 {
      position: absolute;
      left: -21px;
      top: -55px;
      height: 29px;
      font-size: 15px;
      margin: 0;
      border-radius: 5px 5px 0 0;
      z-index: 1;
      display: block;
      a {
        display: block;
        margin: 5px 0 0 0;
        text-align: center;
        text-decoration: none;
        border-top: solid 1px #cccccc;
        border-left: solid 1px #cccccc;
        border-right: solid 1px #cccccc;
        color: white;
        @media all and (max-width: 530px) {
          color: #A0A0A0 !important;
        }
      }
    }
    &:first-child h2 {
      width: 124px;
    }
    &:last-child h2 {
      width: 224px;
    }
    &:nth-child(2) h2 {
      left: 102px;
      z-index: 0;
    }
    &:nth-child(3) {
      h2 {
        left: 240px;
        z-index: -1;
      }
    }
  }
}

article.infobox section:target, article.infobox section:target h2 {
  /* background: rgba(255, 255, 255, 0.8); */
  z-index: 2;
  display: block;
}

article.infobox section:target .body {
  position: relative;
  display: block;
  opacity: 1.0;
  z-index: 1;
}

article.infobox section:target h2 a {
  color: black;
  background: white;
  @media all and (max-width: 530px) {
    color: black !important;
  }
}

article {
  position: relative;
}

