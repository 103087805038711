.headButton {
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  float: right;
  height: 30px;
  a {
    height: 30px;
    font-size: medium;
  }
}


.opener {
  height: 0px;
  transition: all 2s ease-in-out;
  overflow: auto;
  opacity: 0;
  &.open {
    height: auto;
    opacity: 1;
  }
}

.vLabel {
  margin-top: 30px !important;
  margin-bottom: 5px !important;
  font-weight: normal;
  &:first-child {
    margin-top: 0px !important;
  }
}
.check {
  label {
    padding-top: 3px;
  }

  input.error + i + label:before {
    border: 1px solid #cf3f17 !important;
  }
}

.ui-datepicker{z-index: 998 !important;}
.form input[type=checkbox],
.form input[type=radio] {
  width: 100%;
  height: 30px;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 100;
}
.form input[type=radio] {
  width: 26px;
  height: 26px;
}

.hidden {
  opacity: 0;
  transition: all 1s;
  z-index: -1;
  &.show {
    opacity: 1;
    z-index: 1;
  }
}

.check {
  position: relative;
  input[type='radio'].error + i + label:before {
    border: 1px solid #cf3f17;
  }
  .label.invalid {
    display: none;
  }
  input[type='checkbox'], input[type='radio'] {
    opacity: 0;
    height: 22px;
    width: 22px;
    overflow: hidden;
    position: absolute;
    cursor: pointer;

    & + i {
      cursor: pointer;
      display: block;
      &:after, &:before {
        content: "";
        position: absolute;
        width: 0;
        height: 3px;
        background: #505050;
        transform-origin: left;
        transition: all 150ms;
        left: 1px;
        top:1px;
      }

      &:after {
        transform: translate(7px, 13px) rotate(-45deg);

      }

      &:before {
        transform: translate(3px, 8px) rotate(45deg);
      }
    }



    & + i + label {
      min-height: 24px;
      //overflow: hidden;
      //margin-left: -25px;
      padding-left: 35px;
      cursor: pointer;
      font-size: 14px;
      position: relative;
      display: block;
      margin-bottom: 0;

      &:before {
        width: 22px;
        height: 22px;
        display: inline-block;
        left: 0;
        top: 0%;
        position: absolute;

        border-radius: 4px;
        border: 1px solid #9e9e9e;
        margin-left: 0;
        margin-right: .61111111rem;
        margin-bottom: 4px;
        font-size: 1rem;
        content: "";
      }
    }

    &:checked {
      & + i {
        &:after {
          width: 14px;
          transition-delay: 150ms;
        }

        &:before {
          width: 8px;
          transition-delay: 0ms;
        }
      }
    }
  }
}

.check.disabled input[type="checkbox"] + i + label::before, .check.disabled input[type="radio"] + i + label::before {
  border: 1px solid #D0D0D0;
}

.check.disabled input[type="checkbox"] + i + label, .check.disabled input[type="radio"] + i + label {
  color: #D0D0D0;
}


.form input[type=checkbox]:checked + label::before {
  font-family: "FontAwesome";
  display: block;
  content: "\f00c";
  font-size: 1em;
  vertical-align: middle;
  text-align: center;
}
.form input[type=radio]:checked + label::before {
  font-family: "FontAwesome";
  display: block;
  content: "\f111";
  font-size: 14px;
  vertical-align: middle;
  text-align: center;
}
.form .checker-label {
  display: block;
  padding: 5px 0;
  float: left;
  font-weight: normal;
  margin-left: 0;
  padding-left: 40px;
  a {
    position: relative;
    z-index: 999;
  }
}

.form .checker-label::before {
  content: '';
  overflow: hidden;
  width: 30px;
  height: 30px;
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 5px;
  background: #ffffff;
  border: 1px solid #cccccc;
}

.form .checker-label.radio::before {
  content: '';
  overflow: hidden;
  width: 26px;
  height: 26px;
  display: block;
  position: absolute;
  left: 0;
  top: 2px;
  border-radius: 50%;
  background: #ffffff;
  border: 1px solid #cccccc;
}

.select-headline {
  margin-bottom: 10px;
}

fieldset.date-field .styled-text {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

fieldset.date-field .input-append {
  width: 100%;
  position: relative;
  padding-right: 38px;
}

fieldset.date-field .input-append-data {
  position: relative;
  padding-right: 38px;
}

fieldset.date-field .add-on {
  position: absolute;
  right: 0;
  top: 0;
  width: 40px;
  height: 40px;
  cursor: pointer;
  text-align: center;
  -ms-behavior: url('css/PIE.htc');
  border: 1px solid #cccccc;
  background-color: #ff8d2f;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border-radius: 5px\9;
}
fieldset.date-field .label_over .add-on {
  top:auto;
  bottom:0;
}
fieldset.date-field .add-on:before {
  padding: 10px 10px 0;
  color: #ffffff;
  display: block;
}

.styled-radio {
  position: relative;
}

.styled-radio input[type=radio] {
  width: 15px;
  position: absolute;
  left: 0;
  top: 17px;
  left: 10px;
  opacity: 0;
  z-index: 100;
  cursor: pointer;
}

.styled-radio .radio-label {
  position: relative;
  cursor: pointer;
  background-color: #ffffff;
  color: #ff8d2f;
  border: 2px solid #ff8d2f;
  padding: 11px 10px;
}

.styled-radio .radio-label:hover {
  border: 2px solid #fb7100;
  color: #fb7100;
  background-color: #ffffff;
}

.styled-radio .radio-label:before {
  content: 'Auswählen';
  display: inline-block;
}

.styled-radio input[type=radio]:checked + label {
  background-color: #ff8d2f;
  color: #ffffff;
}

.styled-radio input[type=radio]:checked + label:hover {
  background-color: #fb7100;
}

.styled-radio input[type=radio]:checked + label:before {
  content: 'Ausgewählt';
  font-family: "FontAwesome";
  display: block;
  content: "\f00c";
  font-size: 1em;
  vertical-align: middle;
  text-align: center;
}

.styled-select {
  border-radius: 5px;
  background: transparent;
  background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSI1NSUiIHN0b3AtY29sb3I9IiNmZmZmZmYiIHN0b3Atb3BhY2l0eT0iMSIvPgogICAgPHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjZGJkYmRiIiBzdG9wLW9wYWNpdHk9IjEiLz4KICA8L2xpbmVhckdyYWRpZW50PgogIDxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxIiBoZWlnaHQ9IjEiIGZpbGw9InVybCgjZ3JhZC11Y2dnLWdlbmVyYXRlZCkiIC8+Cjwvc3ZnPg==);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #ffffff), color-stop(100%, #dbdbdb));
  background: -webkit-linear-gradient(top, #ffffff 55%, #dbdbdb 100%);
  background: linear-gradient(to bottom, #ffffff 55%, #dbdbdb 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#dbdbdb',GradientType=0 );
  overflow: hidden;
  border: 1px solid #cccccc;
  cursor: pointer;
  position: relative;
  display: block;
  color: #8a8a8a;
  font-weight: 600;
  font-family: "Bliss", Arial, sans-serif;
}

.styled-select option {
  background-color: transparent;
}

.styled-select:before {
  position: absolute;
  right: 12px;
  height: 100%;
  top: 10px;
  color: #8a8a8a;
  z-index: -1;
  font-family: "FontAwesome";
}

.styled-select select {
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 120%;
  font-family: "Bliss", Arial, sans-serif;
  border: 0;
  outline: 0;
  cursor: pointer;
  padding: 10px 12px;
  color: #787878;
}

.styled-select select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #787878;
}

.input-inline-wrapper {
  display: block;
}

.input-inline-wrapper > div {
  border-left-width: 5px;
}

.input-inline-wrapper > div > input {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
  border-bottom-right-radius: 5px !important;
  border-top-right-radius: 5px !important;
}

.input-inline-wrapper > div:first-child {
  border-left-width: 15px;
}

.input-inline-wrapper > div:first-child > input {
  border-bottom-left-radius: 5px !important;
  border-top-left-radius: 5px !important;
  border-bottom-right-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.two-third {
  float: left;
}

.one-third {
  float: left;
}

.fieldset-wrap fieldset.info-text {
  position: static;
  padding-bottom: 0;
  margin-bottom: 25px;
}

fieldset textarea {
  min-height: 200px;
}

.form .styled-text.meter-reading-round {
  width: 75%;
}

.form-wrapper {
  padding-left: 20px;
  padding-right: 20px;
}

.form.error-frame {
  width: 100%;
}

.form {
  border-left: 0;
  border-right: 0;
}

.table-verbrauch {
  padding-left: 20px;
  padding-right: 20px;
}

.form-login-info {
  margin-left: 20px;
  margin-right: 20px;
}

.form-login-info .form-text {
  padding: 0;
}

.form-text {
  padding-left: 20px;
  padding-right: 20px;
}

.form-info-label {
  padding-left: 20px;
  padding-right: 20px;
}

.styled-text {
  display: block;
  width: 100%;
  padding: 8px;
  height: 40px;
  border: 1px solid #cccccc;
  border-radius: 5px;
}

.form .styled-text.meter-reading-round {
  width: 85%;
  float: left;
}

.form-wrapper {
  margin-bottom: 18px;
}

.form.chrome {
  background-color: #ffffff;
  background-color: rgba(255, 255, 255, 0.8);
  border: 1px solid #cccccc;
  padding: 25px 20px;
}

.form.error-frame {
  margin: auto;
  width: 50%;
}

.form.error-frame > .main-col {
  float: none;
}

.form.error-frame > .headline.desktop {
  width: 100%;
}

.form label {
  color: #140043;
  font-size: 16px;
  display: block;
  margin-bottom: 8px;
  font-weight: 600;
}

.form-help {
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  color: #8a8a8a;
  margin-bottom: 20px;
}

fieldset.error .styled-text,
fieldset.error .styled-select,
fieldset.error .styled-input + .checker-label:before,
fieldset.error textarea {
  border: 1px solid #cf3f17;
}

fieldset.error label {
  color: #cf3f17;
}

.styled-input.error + .checker-label:before,
.styled-text.error, .bs-styles .form-group .form-control.error {
  border: 1px solid #cf3f17;
}

.form-login-info {
  margin-bottom: 20px;
  padding-bottom: 20px;
}

.form-login-info p {
  margin: 0;
}

.form-error {
  margin-bottom: 20px;
}

.form-error-item {
  background: #cf3f17;
  color: #ffffff;
  margin-bottom: 10px;
  padding: 10px 20px;
  padding-left: 35px;
  position: relative;
}

.form-error-item:before {
  display: block;
  position: absolute;
  left: 18px;
  font-size: 20px;
  top: 10px;
}

.form-info-label {
  font-size: 14px;
  color: #8a8a8a;
  margin-bottom: 20px;
}

.check-box {
  cursor: pointer;
  .widget_body {
    cursor: pointer;
    height: 160px !important;
  }
  .headline {
    width: 100%;
    display: block;
    border-left-width: 0px;
  }
  input[type="radio"]:checked + label::before {
    font-family: FontAwesome;
    display: block;
    content: "\f00c";
    font-size: 40px;
    position: absolute;
    right: -10px;
    top: -15px;
    z-index: 1;
    transform: rotate(-12deg);
  }
}

.second {
  margin-left: 10px;
}

.gesperrt {
  font-style: italic;
  color: #D0D0D0;
  input {
    background: #d0d0d0;
  }
}

.errorText {
  position: absolute;
  color: #cf3f17;
  margin-top: 3px;
  font-size: 13px;
}

.nice-select.error {
  border: solid 1px #cf3f17;
}

.ui-state-active, .ui-widget-content .ui-state-active, .ui-widget-header .ui-state-active, a.ui-button:active, .ui-button:active, .ui-button.ui-state-active:hover {
  background: #A0A0A0;
  border: 1px solid #D0D0D0;
}

button {
  position: relative;
}
#lo {
  background: none;
  max-height: 20px;
  min-height: 20px;
  right: 50%;
  margin-right: -20px;
  top: 50%;
  margin-top: -10px;
  div{
    height:20px;
    width:10px;
    display:inline-block;
    background: #cccccc;
    border:1px solid #666666;

    animation:loader_loader 1.3s linear infinite;

    transform: scale(0.91);
    &:nth-child(1){
      -webkit-animation-delay:0.39s;
      animation-delay:0.39s;
    }
    &:nth-child(2){
      -webkit-animation-delay:0.52s;
      animation-delay:0.52s;
    }
    &:nth-child(3){
      -webkit-animation-delay:0.65s;
      animation-delay:0.65s;
    }
  }
}