/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 200px;
  background: #0196ac;
  color: #fff;
  text-align: center;
  padding: 5px;
  border-radius: 6px;
  font-size: 18px;
  font-family: 'HelveticaW01', sans-serif;
  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}

.optinBubble {
  background: red;
  width: 16px;
  height: 16px;
  position: absolute;
  border-radius: 8px;
  font-size: 11px;
  text-align: center;
  color: white;
  &.mainBubble {
    top: 5px;
    right: 0px;
  }
  &.subBubble {
    top: -5px;
    right: -5px;
  }
}

.optins .form .checker-label::before {
  top: 30px;
}

.optins .form .checker-label {
  padding-left: 0;
}

.optins .form .check {
  padding-right: 40px;
}

.optins .form input[type="checkbox"]:disabled + label::before {
  color: #d0d0d0 !important;
  border: 2px solid #d0d0d0;
}

.optins .slideBox label {
  font-size: 1rem;
  font-weight: normal;
}

.optinLanding {
  .optins {
    .button {
      padding: 13px 10px !important;
    }
  }
}
