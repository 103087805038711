/*!
 * Bootstrap v5.1.3 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */

// scss-docs-start import-stack
// Configuration
@import "./node_modules/bootstrap/scss/functions";
@import "global/bootstrap_variables";
@import "./node_modules/bootstrap/scss/variables";
@import "./node_modules/bootstrap/scss/mixins";
@import "./node_modules/bootstrap/scss/utilities";

// Layout & components 
//@import "./node_modules/bootstrap/scss/root";
//@import "./node_modules/bootstrap/scss/reboot";
//@import "./node_modules/bootstrap/scss/type";
//@import "./node_modules/bootstrap/scss/images";
//@import "./node_modules/bootstrap/scss/containers";
@import "./node_modules/bootstrap/scss/grid";
//@import "./node_modules/bootstrap/scss/tables";
//@import "./node_modules/bootstrap/scss/forms";
//@import "./node_modules/bootstrap/scss/buttons";
@import "./node_modules/bootstrap/scss/transitions";
//@import "./node_modules/bootstrap/scss/dropdown";
//@import "./node_modules/bootstrap/scss/button-group";
//@import "./node_modules/bootstrap/scss/nav";
//@import "./node_modules/bootstrap/scss/navbar";
//@import "./node_modules/bootstrap/scss/card";
//@import "./node_modules/bootstrap/scss/accordion";
//@import "./node_modules/bootstrap/scss/breadcrumb";
//@import "./node_modules/bootstrap/scss/pagination";
//@import "./node_modules/bootstrap/scss/badge";
//@import "./node_modules/bootstrap/scss/alert";
//@import "./node_modules/bootstrap/scss/progress";
//@import "./node_modules/bootstrap/scss/list-group";
//@import "./node_modules/bootstrap/scss/close";
//@import "./node_modules/bootstrap/scss/toasts";
//@import "./node_modules/bootstrap/scss/modal";
//@import "./node_modules/bootstrap/scss/tooltip";
//@import "./node_modules/bootstrap/scss/popover";
@import "./node_modules/bootstrap/scss/carousel";
//@import "./node_modules/bootstrap/scss/spinners";
//@import "./node_modules/bootstrap/scss/offcanvas";
//@import "./node_modules/bootstrap/scss/placeholders";

// Helpers
//@import "./node_modules/bootstrap/scss/helpers";

// Utilities
@import "./node_modules/bootstrap/scss/utilities/api";
// scss-docs-end import-stack


@import "mixins";
@import "awesome/fontawesome";
@import "awesome/solid";
@import "awesome/regular";
@import "awesome/animated";
@import "global/global";
@import 'modules/modul';

// Neue Bootstrap Styles
.bs-styles {
  @import "global/bs-typho";
  @import "global/bs-wrapper";
  @import "global/bs-colors";
  @import "global/bs-buttons";
  @import "global/bs-icons";
  @import "global/bs-forms";
  @import "modules/listsBS";
  @import "modules/cardBS";
  @import "modules/cardBSAbschlag";
  @import "modules/cardBSAktuelles";
  @import "modules/card-quicklinks";
  @import "modules/zaehlerstandBS";
  .content-holder a.info-toggle {
    right: 12px;
    color: white;
  }
  @import "modules/slick";
  @import "modules/progressBS";
  @import "modules/vertragswechselBS";
}

@font-face {
  font-family: 'FontAwesome';
  src: url('../../fonts/fontawesome-webfont.eot');
  src: url('../../fonts/fontawesome-webfont.eot?#iefix') format('embedded-opentype'), url('../../fonts/fontawesome-webfont.svg#FontAwesome') format('svg'), url('../../fonts/fontawesome-webfont.woff2') format('woff2'), url('../../fonts/fontawesome-webfont.woff') format('woff'), url('../../fonts/fontawesome-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}


.canvasjs-chart-canvas {
  position: relative !important;
}
@media (max-width: 530px) {
  .dokumente {
    .bs-styles .card.main-card .card-body {
      padding: 1rem;
      .widget-archiv .archiv-list-header .archiv-list-column:nth-child(2) {
        display: none;
      }
      .widget-archiv .archiv-list-column:nth-child(2) {
        display: none;
      }
      .widget-archiv .archiv-list-column {
        padding: 10px 10px 10px 0;
        padding-top: 10px;
        padding-bottom: 10px;
        white-space: normal;
        overflow: visible;
        border-left-width: 10px;
      }
      .widget-archiv .archiv-list-column:nth-child(1) {
        width: 60%;
      }
      .widget-archiv .archiv-list-column:nth-child(2) {
        width: 40%;
      }
    }
  }
}

.fa-close::before {
  content: "\f410";
  background: none;
}

.modal-close-button {
  background-color: transparent;
}

.content, .nav_main, .header .inner {
  max-width: 2000px;
  position: relative;
  margin: auto;
  text-align: left;
  float: none;
}

.beholder {
  position: relative;
}

.eye_pin {
  position: absolute;
  z-index: 1;
  right: 5px;
  top: 5px;
  width: 30px;
  height: 30px;
  cursor: pointer;
  text-indent: -100000px;
  background: url(/rpgassets/img/eye_open.png) 0 0 no-repeat;
  &.open {
    background: url(/rpgassets/img/eye_close.png) 0 0 no-repeat;
  }
}