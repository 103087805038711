// Bliss regular
@font-face {
  font-family: 'Bliss';
  src: url('../fonts/blisrg__-webfont.woff2') format('woff2'),
  url('../fonts/blisrg__-webfont.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
// Bliss bold
@font-face {
  font-family: 'Bliss';
  src: url('../fonts/blisb___-webfont.woff2') format('woff2'),
  url('../fonts/blisb___-webfont.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}