.zaehlerstand {
	&.card {
		@include media-breakpoint-down(md) {
			background: $white;
			padding: 1rem;
			& > .card-body {
				padding: 0;
			}
		}
	}
    .head-row {
      	border-bottom:1px solid $border;
		padding: 2.5rem 0 1rem;
    }
	.ofh {
		overflow:hidden;
	}
	.mobile-card {
		border-radius: .5rem;
		.icon.ea {
			margin-right: 1rem;
		}

	}
	.carousel {
		background: $white;
	}


	@include media-breakpoint-down(md) {
		.icon-header {
			margin-bottom: 10px;
		}
		.filter-content {
			.fa-search {
				display: none;
			}
		}
		form {
			padding-bottom: .5rem;
		}
		.head-row {
			display:none;
		}
		.slick-slide-zaehlerstand {
			.slick-list {
				margin:0 -.5rem;
				padding-right: 2rem;
				overflow: initial;
				.mobile-card {
					margin: 0 .5rem 0;
				}
			}
		}
		.mobile-card {
			background: $white;
			border: solid 1px #d1d1d1;
			border-radius: 8px;
			margin-bottom: 1.5rem;
			.card-body {
				padding:1.25rem 1rem;
			}
			/*
			&.strom {
				border-color:$strom;
			}
			&.gas {
				border-color:$gas;
			}
			&.wasser {
				border-color:$wasser;
			}
			&.waerme {
				border-color:$waerme;
			}

			 */
			&.top-card {
				border:none;
				.card-body {
					padding: 0;
				}
			}
			.content-row {
				.col {
					margin-bottom: 1rem;
					&:before {
						content: attr(name);
						word-break: break-word;
						color:$gray-600;
						display:block;
						margin-bottom:.25rem;
					}
				}
			}
		}
		.carousel-controls {
			position: relative;
			display: flex;
			align-items: center;
			padding: 0 0 1rem;
			justify-content: center;
			.carousel-indicators, {
				position: relative;
				margin: 0;
			}

			.carousel-indicators {
				button {
					width: .875rem;
					height: .875rem;
					border: 2px solid $black;
					border-radius: 100%;
					opacity: 1;
					margin: 0 .75rem 0 0;
					box-sizing: border-box;
					outline: none;

					&.active {
						background: $black;
					}
				}
			}
		}
	}
	@include media-breakpoint-up(sm) {
		.carousel-controls {
			display:none;
		}
		.carousel-item {
			display:block!important;
			transform: none!important;
			float:none;
		}
	}
	@include media-breakpoint-up(md) {
		.head-row {
			margin-bottom:1rem;
		}
		.mobile-card {
			padding: .5rem 0;
		}
	}

	// Ansicht Auswahl Lieferstellen
	.bs-item {
		&.multi-accordion-title {
			@include media-breakpoint-down(md) {
				padding-right: 40px;
				.multi-accordion-toggle {
					background-position: right 0 top;
				}
			}
		}
		&.multi-accordion-list-item {
			.multi-accordion-header {
				padding:.5rem 2rem;
				display:flex;
				justify-content: space-between;
				align-items: center;
				.label {
					display:flex;
					align-items: center;
					.icon  {
						margin-right:1rem;
					}
				}
				.custom-control {
					top:15px;
				}
				@include media-breakpoint-down(md) {
					padding:.5rem 1rem;
				}
			}
		}
		&.multi-accordion-header-toggle {
			h3 {
				margin-bottom: 0;
			}
			.multi-accordion-header-icon {
				top:50%;
				transform: translate(0,-50%);
			}
			.multi-accordion-toggle {
				top:50%;
				margin-top:-20px;
			}
			@include media-breakpoint-down(md) {
				padding-left:50px;
				.multi-accordion-header-icon {
					left: 5px;
				}
				.multi-accordion-toggle {
					right:5px;
				}
				h3 {
					padding-right:20px;
				}
			}
		}
		&.icon-header {
			h3 {
				margin-bottom: 0;
			}
			.icon-header-icon {
				top:50%;
				transform: translate(0,-50%);
			}
		}
	}
	&.bs-item {
		&.card {
			@include media-breakpoint-down(lg) {
				background: $white;
				.card-body {
					padding:1.25rem 1rem;
				}
			}
		}
	}
}
// Success Messaeg
.success-msg {
	padding:2rem 2.5rem 2rem 2rem;
	background: $success;
	color:$white;
	border-radius: 8px;
	box-shadow: 0 0 12px 0 rgba(58, 134, 61, 0.32);
	position: fixed;
	right:1.5rem;
	bottom:1.5rem;
	max-width: 20rem;
	z-index: 99;
	.msg-head {
		font-size:$h5-font-size;
		font-weight:700;
		margin-bottom: .5rem;
	}
	p {
		&:last-child {
			margin-bottom: 0;
		}
	}
	.close {
		position:absolute;
		top:0.75rem;
		right:0.75rem;
		width:1.5rem;
		height:1.5rem;
		background-image: url("data:image/svg+xml,%0A%3Csvg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z' fill='%23FFF' fill-rule='nonzero'/%3E%3C/svg%3E%0A");
		background-size: 1.5rem;
		background-position: center;
		background-repeat: no-repeat;
		cursor:pointer;
	}
	@include media-breakpoint-down(lg) {
		bottom:auto;
		top:0;
	}
}