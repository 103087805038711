.products {
    margin-top: 100px;
}
.products-wrapper {
    text-align: center;
    .product {
        margin: auto;
        width: 35%;
        padding: 15px;
        float: none;
        display: inline-block;
        position: relative;
        input[type='radio'] {
            position:absolute;
            top:0;
            left: 0;
            width: 100%;
            height:100%;
            opacity: 0.0;
            /*
            &:checked + .body {
                border: dotted $forground 3px;
            }*/
        }
        .body.small {
            height: auto;
        }
        .body {
            box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.125);
            /* height: 500px; */
            /* background: $white; */
            text-align: left;
            
            h2 {
                /* background: $main-light;
                color: $forground; */
                padding: 25px;
                text-align: center;
                margin: 0;
            }
            
            .widget-extra-item {
                position: absolute;
                top: 20px;
                right: 20px;
            }
            section {
                padding: 15px;
                p {
                    margin: 0 0 18px;
                    padding-top: 5px;
                    font-size: 14px;
                    font-weight: lighter;
                    /* color: $main-dark; */
                }
            }
        }
    }
}