$color_page_bg: rgba(255,255,255,1.0) !default;
$color_white: rgba(255,255,255,1.0) !default;

$color_green: rgba(151, 190, 14, 1.0) !default;
$color_yellow: rgba(255,204,0,1.0) !default;
$color_blue: rgba(0, 94, 168, 1.0);
$color_light_gray: #e5e5e5 !default;
$color_dark_gray: rgba(212, 212, 212, 1.0) !default;
$color_border: $color_light_gray;

$color_topbar_gradient_start: rgba(255,255,255,1.0) !default;
$color_topbar_gradient_stop: rgba(180,180,180,1.0) !default;

$color_font: rgba(102,102,102,1.0) !default;
$color_headlines: $color_blue;
$color_links: $color_blue;

$color_error: rgba(221,109,109,1) !default;




$border_radius: 0;

.green_bg {
  color: $color_white;
  background-color: $color_green;
  position: relative;
  p, a {
    color: $color_white;
  }
}
.blue_bg {
  color: $color_white;
  background-color: $color_blue;
}
.light_gray_bg {
  color: $color_font;
  background: $color_light_gray;
  position: relative;
  padding-top: 50px;
  padding-bottom: 50px;
}
.dark_gray_bg {
  color: $color_font;
  background-color: $color_dark_gray;
}

.red_bg {
  background-color: red;
}

.font_bg {
  color: $color_white;
  background-color: $color_font;
}

.tracks .td {
  vertical-align: middle;
  position: relative;
  .date {
      padding-right: 8px;
  }
}
.tracks .tr {
  color: $color_dark_gray;
}
.tracks .tr.active {
  color: $color_font;
  font-weight: bold;
}
.tracks .tr .td:nth-child(2) {
  width: 30px;
  vertical-align: top;
  overflow:hidden;
}
.tracks .tr:last-child .td .line {
  height: 5px;
  width: 2px;
}
.tracks .tr:nth-child(1) .td .line {
  top: 7px;
}
.tracks .line {
  position:absolute;
  width: 2px;
  margin-left: 5px;
  background: $color_dark_gray;
  height:1000px;
  z-index: 0;
  top:0;
  bottom:0;
}
#section_content .tracks p {
  line-height: 1;
  float: none;
  margin: 0;
  padding-bottom: 20px;
  font-size: 14px;
  font-family: Verdana, Arial, sans-serif;
}
.tracks .dot {
  width: 12px;
  height: 12px;
  border-radius: 6px;
  z-index: 5;
  position: relative;
}
.table {
    display: table;
    border-collapse:collapse;
    width: auto;
    left: 50%;
    position: relative;
    transform: translateX(-50%);
}
.tr {
  display:table-row;
}
.td {
  display:table-cell;
  padding:5px;
}

