.icon {
  width:1.5rem;
  height:1.5rem;
  border-radius:0.25rem;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 1.25rem;
  &.med {
    width:1.75rem;
    height:1.75rem;
    background-size:1.75rem;
  }
  &.big {
    width:2rem;
    height:2rem;
    background-size:2rem;
  }
  &.strom {
    background-color: $strom;
    background-image: url("data:image/svg+xml,%0A%3Csvg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.164 17.5H8.33l.834-5.833H6.247c-.483 0-.475-.267-.317-.55.159-.284.042-.067.059-.1C7.064 9.117 8.68 6.283 10.83 2.5h.834l-.834 5.833h2.917c.408 0 .467.275.392.425l-.059.125C10.797 14.625 9.164 17.5 9.164 17.5z' fill='%23FFF' fill-rule='nonzero'/%3E%3C/svg%3E%0A");
  }
  &.gas {
    background-color: $gas;
    background-image: url("data:image/svg+xml,%0A%3Csvg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16.233 10.712c-1.308-3.4-5.966-3.584-4.841-8.525a.42.42 0 0 0-.625-.459c-3.025 1.784-5.2 5.359-3.375 10.042.15.383-.3.742-.625.492C5.258 11.12 5.1 9.478 5.233 8.303c.05-.433-.516-.641-.758-.283-.567.867-1.142 2.267-1.142 4.375.317 4.667 4.259 6.1 5.675 6.283 2.025.259 4.217-.116 5.792-1.558 1.733-1.608 2.367-4.175 1.433-6.408zM8.5 14.903c1.2-.291 1.817-1.158 1.983-1.925.275-1.191-.8-2.358-.075-4.241.275 1.558 2.725 2.533 2.725 4.233.067 2.108-2.216 3.917-4.633 1.933z' fill='%23FFF' fill-rule='nonzero'/%3E%3C/svg%3E%0A");
  }
  &.wasser {
    background-color: $wasser;
    background-image: url("data:image/svg+xml,%0A%3Csvg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10 2.5c-3.886 3.412-5.833 6.36-5.833 8.85 0 3.735 2.77 6.15 5.833 6.15s5.833-2.415 5.833-6.15c0-2.49-1.947-5.438-5.833-8.85zm-2.805 9.167c.26 0 .472.173.521.413.289 1.48 1.604 1.987 2.561 1.914.303-.014.556.213.556.5 0 .266-.225.486-.506.5-1.5.086-3.251-.727-3.652-2.747-.057-.3.197-.58.52-.58z' fill='%23FFF' fill-rule='nonzero'/%3E%3C/svg%3E%0A");
  }
  &.waerme {
    background-color: $waerme;
    background-image: url("data:image/svg+xml,%0A%3Csvg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M18.333 18.333H1.667v-10l5.833-2.5V7.5l4.167-1.667v2.5h2.5L15 1.667h2.5l.833 6.666v10zM10 8.292 5.833 9.958V8.333l-2.5 1.1v7.234h13.334V10H10V8.292zM9.167 15h1.666v-3.333H9.167V15zm-3.334 0H7.5v-3.333H5.833V15zm8.334-3.333H12.5V15h1.667v-3.333z' fill='%23FFF' fill-rule='nonzero'/%3E%3C/svg%3E%0A");
  }

  &.Strom {
    background-color: $strom;
    background-image: url("data:image/svg+xml,%0A%3Csvg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.164 17.5H8.33l.834-5.833H6.247c-.483 0-.475-.267-.317-.55.159-.284.042-.067.059-.1C7.064 9.117 8.68 6.283 10.83 2.5h.834l-.834 5.833h2.917c.408 0 .467.275.392.425l-.059.125C10.797 14.625 9.164 17.5 9.164 17.5z' fill='%23FFF' fill-rule='nonzero'/%3E%3C/svg%3E%0A");
  }
  &.Gas {
    background-color: $gas;
    background-image: url("data:image/svg+xml,%0A%3Csvg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16.233 10.712c-1.308-3.4-5.966-3.584-4.841-8.525a.42.42 0 0 0-.625-.459c-3.025 1.784-5.2 5.359-3.375 10.042.15.383-.3.742-.625.492C5.258 11.12 5.1 9.478 5.233 8.303c.05-.433-.516-.641-.758-.283-.567.867-1.142 2.267-1.142 4.375.317 4.667 4.259 6.1 5.675 6.283 2.025.259 4.217-.116 5.792-1.558 1.733-1.608 2.367-4.175 1.433-6.408zM8.5 14.903c1.2-.291 1.817-1.158 1.983-1.925.275-1.191-.8-2.358-.075-4.241.275 1.558 2.725 2.533 2.725 4.233.067 2.108-2.216 3.917-4.633 1.933z' fill='%23FFF' fill-rule='nonzero'/%3E%3C/svg%3E%0A");
  }
  &.Wasser {
    background-color: $wasser;
    background-image: url("data:image/svg+xml,%0A%3Csvg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10 2.5c-3.886 3.412-5.833 6.36-5.833 8.85 0 3.735 2.77 6.15 5.833 6.15s5.833-2.415 5.833-6.15c0-2.49-1.947-5.438-5.833-8.85zm-2.805 9.167c.26 0 .472.173.521.413.289 1.48 1.604 1.987 2.561 1.914.303-.014.556.213.556.5 0 .266-.225.486-.506.5-1.5.086-3.251-.727-3.652-2.747-.057-.3.197-.58.52-.58z' fill='%23FFF' fill-rule='nonzero'/%3E%3C/svg%3E%0A");
  }
  &.Waerme {
    background-color: $waerme;
    background-image: url("data:image/svg+xml,%0A%3Csvg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M18.333 18.333H1.667v-10l5.833-2.5V7.5l4.167-1.667v2.5h2.5L15 1.667h2.5l.833 6.666v10zM10 8.292 5.833 9.958V8.333l-2.5 1.1v7.234h13.334V10H10V8.292zM9.167 15h1.666v-3.333H9.167V15zm-3.334 0H7.5v-3.333H5.833V15zm8.334-3.333H12.5V15h1.667v-3.333z' fill='%23FFF' fill-rule='nonzero'/%3E%3C/svg%3E%0A");
  }


  &.arrow {
    width:1.5rem;
    height: 1.5rem;
    background-image: url("data:image/svg+xml,%0A%3Csvg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m12 4-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z' fill='%231A1A1A' fill-rule='nonzero'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-size: 1.5rem;
    background-position: center;
  }
  &.zaehlerstand {
    background-image: url("data:image/svg+xml,%0A%3Csvg width='32' height='32' viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14.667 22.667c0 .733.6 1.333 1.333 1.333s1.333-.6 1.333-1.333c0-.734-.6-1.334-1.333-1.334s-1.333.6-1.333 1.334zm0-18.667v5.333h2.666v-2.56c4.52.654 8 4.52 8 9.227A9.327 9.327 0 0 1 16 25.333 9.327 9.327 0 0 1 6.667 16c0-2.24.786-4.293 2.106-5.893L16 17.333l1.88-1.88-9.067-9.066v.026C5.893 8.6 4 12.067 4 16c0 6.627 5.36 12 12 12 6.627 0 12-5.373 12-12S22.627 4 16 4h-1.333zM24 16c0-.733-.6-1.333-1.333-1.333-.734 0-1.334.6-1.334 1.333s.6 1.333 1.334 1.333c.733 0 1.333-.6 1.333-1.333zM8 16c0 .733.6 1.333 1.333 1.333.734 0 1.334-.6 1.334-1.333s-.6-1.333-1.334-1.333C8.6 14.667 8 15.267 8 16z' fill='%231A1A1A' fill-rule='nonzero'/%3E%3C/svg%3E%0A");
  }
  &.abschlag {
    background-image: url("data:image/svg+xml,%0A%3Csvg width='32' height='32' viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M25.333 4C26.8 4 28 5.2 28 6.667v18.666C28 26.8 26.8 28 25.333 28H6.667A2.675 2.675 0 0 1 4 25.333V6.667C4 5.2 5.2 4 6.667 4zm0 2.667H6.667v18.666h18.666V6.667zm-12.666 10v2.666h2.666v2h-2.666V24h-2v-2.667H8v-2h2.667v-2.666h2zM24 21v2h-6.667v-2H24zm0-3.333v2h-6.667v-2H24zM22.547 8l1.413 1.413-1.88 1.88 1.88 1.894-1.413 1.413-1.88-1.88-1.88 1.88-1.414-1.413 1.88-1.894-1.88-1.88L18.787 8l1.88 1.88L22.547 8zM15 10.293v2H8.333v-2H15z' fill='%231A1A1A' fill-rule='nonzero'/%3E%3C/svg%3E%0A");
  }
  &.simrechnung {
    background-image: url("data:image/svg+xml,%0A%3Csvg width='32' height='32' viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 22.667H9.333v-9.334H12v9.334zm5.333 0h-2.666V9.333h2.666v13.334zm5.334 0H20v-5.334h2.667v5.334zm3.333 2.8H6v-18.8h20v18.8zM26 4H6a2.675 2.675 0 0 0-2.667 2.667v18.666C3.333 26.8 4.533 28 6 28h20c1.467 0 2.667-1.2 2.667-2.667V6.667C28.667 5.2 27.467 4 26 4z' fill='%231A1A1A' fill-rule='nonzero'/%3E%3C/svg%3E%0A");
  }
  &.kundendaten {
    background-image: url("data:image/svg+xml,%0A%3Csvg width='32' height='32' viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m24 14.667.4 1.986c.533.2 1.013.48 1.44.84l1.933-.653 1.334 2.307-1.52 1.346c.04.28.08.547.08.84 0 .294-.04.56-.08.84l1.52 1.347-1.334 2.307-1.933-.654c-.427.36-.907.64-1.44.84L24 28h-2.667l-.4-1.987a4.862 4.862 0 0 1-1.44-.84l-1.933.654-1.333-2.307 1.52-1.347c-.04-.28-.08-.546-.08-.84 0-.293.04-.56.08-.84l-1.52-1.346 1.333-2.307 1.933.653c.427-.36.907-.64 1.44-.84l.4-1.986H24zm-10.667 2.666c.307 0 .6.014.894.04a9.18 9.18 0 0 0-.787 2.64C13.4 20 13.373 20 13.333 20c-2.626 0-5.2.707-7.453 2.053-.333.2-.547.627-.547 1.08V24h8.387a9.51 9.51 0 0 0 1.293 2.667H2.667v-3.534c0-1.373.68-2.68 1.853-3.373a17.168 17.168 0 0 1 8.813-2.427zm9.334 1.334A2.675 2.675 0 0 0 20 21.333C20 22.8 21.2 24 22.667 24c1.466 0 2.666-1.2 2.666-2.667 0-1.466-1.2-2.666-2.666-2.666zM13.333 5.333a5.332 5.332 0 0 1 5.334 5.334A5.332 5.332 0 0 1 13.333 16 5.332 5.332 0 0 1 8 10.667a5.332 5.332 0 0 1 5.333-5.334zm0 2.667a2.675 2.675 0 0 0-2.666 2.667c0 1.466 1.2 2.666 2.666 2.666 1.467 0 2.667-1.2 2.667-2.666C16 9.2 14.8 8 13.333 8z' fill='%231A1A1A' fill-rule='nonzero'/%3E%3C/svg%3E%0A");
  }
  &.dokumente {
    background-image: url("data:image/svg+xml,%0A%3Csvg width='32' height='32' viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M25.333 2.667H19.76C19.2 1.12 17.733 0 16 0c-1.733 0-3.2 1.12-3.76 2.667H6.667A2.675 2.675 0 0 0 4 5.333v21.334c0 1.466 1.2 2.666 2.667 2.666h18.666c1.467 0 2.667-1.2 2.667-2.666V5.333c0-1.466-1.2-2.666-2.667-2.666zm-9.333 0c.733 0 1.333.6 1.333 1.333s-.6 1.333-1.333 1.333-1.333-.6-1.333-1.333.6-1.333 1.333-1.333zm9.333 24H6.667V5.333h2.666v4h13.334v-4h2.666v21.334z' fill='%231A1A1A' fill-rule='nonzero'/%3E%3C/svg%3E%0A");
  }
  &.date {
	  background-image: url("data:image/svg+xml,%0A%3Csvg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M19 4h-1V2h-2v2H8V2H6v2H5c-1.11 0-1.99.9-1.99 2L3 20a2 2 0 0 0 2 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 16H5V10h14v10zm0-12H5V6h14v2zM9 14H7v-2h2v2zm4 0h-2v-2h2v2zm4 0h-2v-2h2v2zm-8 4H7v-2h2v2zm4 0h-2v-2h2v2zm4 0h-2v-2h2v2z' fill='%23FFF' fill-rule='nonzero'/%3E%3C/svg%3E%0A");
  }
  &.calculator {
    width:1rem;
    height: 1rem;
    background-image: url("data:image/svg+xml,%0A%3Csvg width='16' height='16' viewBox='0 0 16 16' xmlns='http://www.w3.org/2000/svg'%3E%3Cg stroke='%23F18700' stroke-width='1.5' fill='none' fill-rule='evenodd'%3E%3Cpath d='M5 13.5v-5M7.5 11h-5M7.5 5h-5M8.5 12.5h5M8.5 9.5h5M9 7l4-4M13 7 9 3M2.75.75h10.5a2 2 0 0 1 2 2v10.5a2 2 0 0 1-2 2H2.75a2 2 0 0 1-2-2V2.75a2 2 0 0 1 2-2z'/%3E%3C/g%3E%3C/svg%3E%0A");
    background-size: 1rem;
  }
  &.phone {
    background-image: url("data:image/svg+xml,%0A%3Csvg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.54 5c.06.89.21 1.76.45 2.59l-1.2 1.2c-.41-1.2-.67-2.47-.76-3.79h1.51m9.86 12.02c.85.24 1.72.39 2.6.45v1.49c-1.32-.09-2.59-.35-3.8-.75l1.2-1.19M7.5 3H4c-.55 0-1 .45-1 1 0 9.39 7.61 17 17 17 .55 0 1-.45 1-1v-3.49c0-.55-.45-1-1-1-1.24 0-2.45-.2-3.57-.57a.84.84 0 0 0-.31-.05c-.26 0-.51.1-.71.29l-2.2 2.2a15.149 15.149 0 0 1-6.59-6.59l2.2-2.2c.28-.28.36-.67.25-1.02A11.36 11.36 0 0 1 8.5 4c0-.55-.45-1-1-1z' fill='%23000' fill-rule='nonzero'/%3E%3C/svg%3E%0A");
  }
  &.mail {
    background-image: url("data:image/svg+xml,%0A%3Csvg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M22 6c0-1.1-.9-2-2-2H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6zm-2 0-8 5-8-5h16zm0 12H4V8l8 5 8-5v10z' fill='%23000' fill-rule='nonzero'/%3E%3C/svg%3E%0A");
  }
  &.tooltip {
    width:1rem;
    height: 1rem;
    background-size: 1rem;
    background-image: url("data:image/svg+xml,%0A%3Csvg width='16' height='16' viewBox='0 0 16 16' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.2 4h1.6v1.6H7.2V4zm0 3.2h1.6V12H7.2V7.2zM8 0C3.584 0 0 3.584 0 8s3.584 8 8 8 8-3.584 8-8-3.584-8-8-8zm0 14.4A6.408 6.408 0 0 1 1.6 8c0-3.528 2.872-6.4 6.4-6.4 3.528 0 6.4 2.872 6.4 6.4 0 3.528-2.872 6.4-6.4 6.4z' fill='%23000' fill-rule='nonzero'/%3E%3C/svg%3E%0A");
  }
}
.fa-calendar {
  &:before {
    display:none;
  }
}

.btn-forward {
    &:after {
      content:"";
      display:block;
      width:1.5rem;
      height: 1.5rem;
      background-image: url("data:image/svg+xml,%0A%3Csvg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m12 4-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z' fill='%23FFF' fill-rule='nonzero'/%3E%3C/svg%3E%0A");
      background-size: 1.5rem;
      background-position: center;
      background-repeat: no-repeat;
      margin-left: .5rem;
    }
}

.hl-check {
  background-image: url("data:image/svg+xml,%0A%3Csvg width='40' height='40' viewBox='0 0 40 40' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M20 5C11.72 5 5 11.72 5 20c0 8.28 6.72 15 15 15 8.28 0 15-6.72 15-15 0-8.28-6.72-15-15-15zm0 27c-6.615 0-12-5.385-12-12S13.385 8 20 8s12 5.385 12 12-5.385 12-12 12zm6.885-18.63L17 23.255l-3.885-3.87L11 21.5l6 6 12-12-2.115-2.13z' fill='%233A863D' fill-rule='nonzero'/%3E%3C/svg%3E%0A");
  background-size: 2.5rem;
  background-position: left center;
  background-repeat: no-repeat;
  padding-left:3rem;
  min-height: 2.5rem;
  display:flex;
  align-items: center;
}