.lieferstellenauswahl-v2 {
  &.form-wrapper {
    background-color: #FFFFFF;
  }
  .table-wrapper {
    tr {
      border: none;
    }
    td {
      padding: 7px 20px 7px 0;
    }
  }
}


.icon-header {
  padding: 20px 20px 20px 80px;
  background-color: #F1F0F5;
  cursor: pointer;
  position: relative;
  line-height: 40px;
  h3 {
    margin: 0;
    padding: 0;
    font-size: 1em;
    font-weight: bold;
  }
  .icon-header-icon {
    position: absolute;
    left: 20px;
    top: 20px;
    width: 40px;
    height: 40px;
  }
}
.check-item-checked {
  background-color: #F1F0F5;
}
.multi-accordion-content .check-item-checked .multi-accordion-header {
  background-color: #F1F0F5;
}
.multi-accordion {
  margin-bottom: 18px;
  .custom-control-label::before {
    position: absolute;
  }
}
.multi-accordion-title {
  padding: 20px 20px 20px 0;
  line-height: 40px;
  margin-bottom: 3px;
  position: relative;
}
.multi-accordion-title h2 {
  margin: 0;
  padding: 0;
  line-height: 40px;
}
.multi-accordion-list-item {
  margin-bottom: 3px;
}
.multi-accordion-header {
  margin-bottom: 3px;
  padding: 20px 20px 20px 80px;
  background-color: #F1F0F5;
  cursor: pointer;
  position: relative;
  line-height: 40px;
}
.multi-accordion-header h3 {
  margin: 0;
  padding: 0;
  font-size: 1em;
  font-weight: bold;
}
.multi-accordion-header .multi-accordion-header-icon {
  position: absolute;
  left: 20px;
  top: 20px;
  width: 40px;
  height: 40px;
}
.multi-accordion-toggle {
  position: absolute;
  top: 20px;
  right: 16px;
  background-image: url("../img/arrow-down.svg");
  background-repeat: no-repeat;
  background-position: center center;
  width: 40px;
  height: 40px;
  cursor: pointer;
}
.multi-accordion-form {
  display: none;
}
.multi-accordion-toggle {

}
.multi-accordion-open,
.multi-accordion-list-item-open > .multi-accordion-header .multi-accordion-toggle {
  transform: rotate(180deg);
  transform-origin: center center;
}


.multi-accordion-content .multi-accordion-header {
  background-color: #FFFFFF;
  margin-bottom: 0;
}

.multi-accordion-add-all {
  text-align: right;
}
.multi-accordion-add-all label {
  display: inline-block;
  padding-left: 10px;
}
.multi-accordion-add-all .text-label {
  padding-right: 20px;
}

.multi-accordion-add-all,
.multi-accordion-content .multi-accordion-content {
  padding: 20px;
  background-color: #FFFFFF;
}



.multi-accordion-content {
  display: none;
}

.multi-accordion-list-item-open>.multi-accordion-content {
  display: block;
}

.button-wrapper-lieferstellen-v2 .button {
  width: auto;
  display: inline-block;
}






.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
}

input[type="radio"], input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 24px;
  height: 24px;
  opacity: 0;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: rgb(236,236,236);
  background-color: #FFFFFF;
}
.custom-checkbox .custom-control-label::before {
  border-radius: 0;
}
.custom-control-label::before {
  position: absolute;
  //top: -12px;
  //left: -1.5rem;
  top:0;
  left:0;
  display: block;
  width: 24px;
  height: 24px;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: rgb(236,236,236) solid 2px;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("../img/checkmark.svg");
}

.custom-control-label::after {
  position: absolute;
  //top: -12px;
  //left: -1.5rem;
  top:0;
  left:0;
  display: block;
  width: 24px;
  height: 24px;
  content: "";
  background: no-repeat 50% / 50% 50%;
}



.multi-accordion-header .custom-control {
  position: absolute;
  top: 28px;
  right: 25px;
}
@media all and (max-width: 768px) {
  .table-wrapper td {
    text-align:right ;
    &:first-child {
      text-align: left;
    }
    &:before {
      text-align: left;
    }
  }
}
.multi-accordion{

  .custom-control-input {
    cursor: pointer;
  }
  .multi-accordion-content {
    .multi-accordion-add-all {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .button-forward:disabled {
        display: none;
      }
      .check-holder {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        padding-right: 24px;
        .custom-control-input {
          position: relative;
        }
        .custom-control {
          position: absolute;
          right: 5px;
          top: 0;
          width: 24px;
        }
        label {
          margin-bottom: 0;
          cursor: pointer;
          padding-left: 0;
          .custom-control-label {
            right:0;
          }
        }
      }
    }
    .custom-control {
      .custom-control-label {
        position: absolute;
        left:0;
        top:0;
        &:before, &:after {
          position: absolute;
          top: 0;
        }
      }
    }
  }
}

