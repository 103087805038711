.modal {
  .modalShadow {
    background: rgba(0, 0, 0, 0.6);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -100;
  }

  .modalClose {
    width: 25px;
    height: 25px;
    color: #555555;
    position: absolute;
    background-color: #cccccc;
    top: 0px;
    right: 0px;
    padding-top: 4px;
    text-align: center;
    cursor: pointer;
    z-index: 910;
  }

  .button {
    transition: all 0.3s linear;
    &:hover {
      transform: scale(1.05,1.05);
    }
  }
}

.optins {
    .button-row.row {
        .col.col-auto {
            .button.twigButton {
              width: auto;
            }
        }
    }
}

