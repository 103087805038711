.icon {
  position: relative;
  width: 30px;
  height: 30px;
  &.formIcon {
    top: 100%;
    transform: translateY(-100%);
    width: calc(1.5em + .75rem + 2px);
    height: calc(1.5em + .75rem + 2px);
  }
  padding: 5px;
  display: inline-block;
  &.E {
    background: red;
  }
  &.G {
    background: #ffb506;
  }
  &.W, &.A {
    background: blue;
  }
  .svg {
    width: 100%;
    height: 100%;
  }
}