.main-nav {
  margin-bottom:0;
  position:relative;
  .nav_main {
    //max-width:1280px;
    //padding:0 1rem;
    margin:0 auto;
  }
}
.content-holder {
  padding-top:2.5rem;
  padding-bottom:3.125rem;
  & > .content {
    max-width:1280px;
    padding-left: 1rem;
    padding-right: 1rem;
    margin: 0 auto;
  }
}
.row {
  .col {
    border-left:none;
  }
}