.widget-row {
  display: flex;
  @media all and (max-width: 530px) {
    display:block;
  }
  .widget {
    float: left;
    position: relative;
    width: 100%;
    margin-bottom: 60px;
    @media all and (max-width: 530px) {
      margin-bottom: 20px;
    }
    .widget_body {
      height: 100%;
      background: #ffffff;
      padding: 18px 20px 68px;
      position: relative;
      box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.15);
      border: 1px solid #cccccc;
      border-radius: 0;
      color: #140043;
      p {
        font-size: 14px;
        color: #555;
      }
    }
    textarea {
      max-height: 280px;
      height: 280px;
      width: 100%;
      max-width: 100%;
      display: block;
      border-radius: 5px;
      border: 1px solid #cccccc;
      padding: 10px;
    }
    .widget_headline {
      margin: 0 0 0;
    }
  }
}

.widget_bars {
  margin-top: 20px;
  left: 20px;
  right: 20px;
  bottom: 90px;
  height: 230px;
}

.widget_bars_inner {
  position: relative;
  height: 100%;
  width: 100%;
}

.widget_bars .bars-wrapper {
  width: 100%;
  display: table;
  height: 100%;
  border-bottom: 1px solid #cccccc;
}

.widget_bars .tbc-wrap {
  display: table-cell;
  height: 100%;
  vertical-align: bottom;
  padding-right: 15px;
}

.widget_bars .bar {
  display: table;
  width: 100%;
  padding: 0 5px 5px;
}

.widget_bars .bar.gas {
  background: linear-gradient(#E7E5EC 0%, #beb9cc 100%);
}

.widget_bars .bar.strom {
  background: linear-gradient(#E7E5EC 0%, #beb9cc 100%);
}

.widget_bars .bar.wasser {
  background: linear-gradient(#E7E5EC 0%, #beb9cc 100%);
}

.widget_bars .bar.waerme {
  background: linear-gradient(#E7E5EC 0%, #beb9cc 100%);
}

.widget_bars .bar-text-type,
.widget_bars .bar-text-amount {
  color: #ffffff;
  vertical-align: bottom;
  display: table-cell;
  width: 100%;
  font-weight: bold;
}

.widget_bars .bar-result span {
  display: block;
  color: #8a8a8a;
}





.widget-full {
  width: 100%;
}








.widget.widget-image .widget_body {
  padding-top: 268px;
}

.widget_button {
  position: absolute;
  bottom: 18px;
  left: 0;
  right: 0;
  padding-left: 20px;
  padding-right: 20px;
}

.widget_button.item-deactivated .button,
.widget_button.item-deactivated .styled-radio .radio-label,
.styled-radio .widget_button.item-deactivated .radio-label {
  background: linear-gradient(to bottom, #f4f4f4 0%, #cdcdcd 100%);
  color: #525294;
}

.widget_button.item-deactivated .button:hover,
.widget_button.item-deactivated .styled-radio .radio-label:hover,
.styled-radio .widget_button.item-deactivated .radio-label:hover {
  border: 0;
}

.widget-toggle,
.widget-close {
  position: relative;
}

.select-wrapper {
  margin-bottom: 20px;
  position: relative;
}

.widget-archiv button.invisible-button {
  text-decoration: underline;
}

.widget-archiv-select-group h3 {
  margin-top: 0;
  font-size: 16px;
  display: block;
  margin-bottom: 8px;
}

.widget-archiv-list {
  margin-top: 20px;
}

.widget-archiv-list-inner {
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.15);
  border: 1px solid #cccccc;
}

.widget-archiv .archiv-list-row {
  background: #eeeeee;
  display: block;
  color: #8a8a8a;
}

.widget-archiv .archiv-list-row.even {
  background: #ffffff;
}

.widget-archiv .archiv-list-header {
  background: #ffffff;
  font-weight: 600;
}

.widget-archiv .archiv-list-header .archiv-list-column {
  padding-top: 18px;
  padding-bottom: 18px;
}

.widget-archiv .archiv-list-column {
  padding: 10px 0;
  padding-left: 5px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.widget.widget-radio {
  margin-bottom: 20px;
}

.widget.widget-radio .widget_image {
  height: 200px;
}

.widget.widget-radio .widget_headline {
  font-size: 22px;
  font-weight: 600;
  margin: 16px 0;
  padding-right: 60px;
}

.widget.widget-radio.widget-image .widget_body {
  padding-top: 200px;
}



@media all and (max-width: 530px) {


  .widget-full {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .widget-col-two .widget .widget_body {
    padding-bottom: 68px;
  }

  .widget-col-two .widget .widget_body p {
    width: 100%;
  }

  .widget-col-two .widget .widget_button {
    width: 100%;
  }



  .select-wrapper {
    padding-left: 20px;
    padding-right: 20px;
  }

  .widget-archiv-select-group {
    margin-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .widget-archiv-select-group:first-child {
    margin-top: 0;
  }

  .widget-archiv .archiv-list-column {
    padding: 10px 10px 10px 0;
  }
}

.bs-styles .card-aktuelles .carousel .carousel-caption p {
  color: black;
}