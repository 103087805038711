.vertrag {
  .info {
    transition: all .4s ease;
    color: #000;
    margin-bottom: 40px;

    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid $cta;
    border-radius: .5rem;
    .productHead {
      display: flex;
      align-items: flex-start;
      font-weight: bold;
      padding-top: 10px;
      padding-left: 25px;
      div:last-child {
        padding-left: 10px;
      }
    }
    .body {
      flex: 1 2 auto;
      min-height: 1px;
      padding: 1rem 3.50rem;
      .newProduct {
        border: 1px solid rgba(0,0,0,.125);
        padding: 5px;
      }
      section {
        &:last-child {
          div {
            .tr {
              .td {
                border-bottom: none;
              }
            }
          }
        }
        &>div {
          display: table;
          width: 100%;
          .tr {
            .td {
              border-bottom: 1px solid rgba(0,0,0,.125);
            }
            .td:nth-child(2) {
              text-align: right;
            }
          }
        }
      }
    }
  }


  .ui-accordion {
    .ui-widget-content {
      border: none !important;
    }
    .ui-accordion-header {
      padding: 0;
    }
    .ui-state-active, .ui-state-default, .ui-state-active:hover, .ui-state-default:hover {
      background: none !important;
      border: none !important;
      color: #000 !important;
    }
    .ui-state-active .ui-icon, .ui-button:active .ui-icon {
      background-image: url("images/ui-icons_444444_256x240.png") !important;
    }
  }

  .ui-accordion-header-icon {
    display: inline;
    position: absolute;
    top: 50%;
    margin-top: -9px;
    left: 2px;
  }

}

