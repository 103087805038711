.kontoauszug {
  th {
    background: white;
    position: sticky;
    top: 0; /* Don't forget this, required for the stickiness */
    box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
  }

  /* show tables */
  table {
    tr.Z {
      display: none;
      &.show {
        display: table-row;
      }
    }
  }

  .main-nav, .header, h1, h2, .info-toggle, .debitorTitle, .filter {
    @media print {
      display: none !important;
    }
  }
  .card-body {
    .roller {
      max-height: 400px;
      overflow-y: scroll;
      overflow-x: hidden;
      color: black !important;
      @media print {
        max-height: 100%;
        overflow-y: hidden;
        overflow-x: hidden;
      }
    }
  }
  .card-marginal {
    @media print {
      display: none !important;
    }
  }
  .printMe {
    display: none;
    @media print {
      display: block !important;
    }
  }
}
@media print {
  .card-body {

  }
  .content > .row:first-child {
    display: none !important;
  }
  .table.account td::before {
    color: black !important;
  }
  .button { display: none !important; }
  .back-to-top {
    display: none !important;
  }
}