

.debitors {
  $colors:
          #2196F3,
          #03A9F4,
          #00BCD4,
          #009688,
          #4CAF50,
          #8BC34A,
          #CDDC39,
          #FFEB3B;

  body {
    background-color: #3F51B5;
  }

  .spinner {
    position: absolute;
    z-index: 1;
    top:3px;
    display: none;
  }

  [class^="ball-"] {
    position: absolute;
    display: block;
    left: 30px;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    transition: all 0.5s;
    animation: circleRotate 4s both infinite;
    transform-origin: 0 250% 0;
  }

  @keyframes circleRotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(1440deg);
    }
  }

  @for $i from 1 through 8 {
    .ball-#{$i} {
      z-index: -#{$i};
      background-color: nth($colors, $i);
      animation-timing-function: cubic-bezier(0.5, 0.3 * $i, 0.9, 0.9);
    }
  }

  .debitorAccordion {
    .debitorItem {
      .debitorTitle {
        position: relative;
        background-image: url(../img/arrow-down.svg);
        background-repeat: no-repeat;
        background-position: 97%;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 3px;
        background-color: #f1f0f5;
        padding-left: 10px;
        div {
          height: max-content;
          font-size: 1em;
          font-weight: 700;
          padding: 10px;
          margin-bottom: 3px;
        }
      }
    }
    .debitorContent {
      opacity: 0;
      height: 0;
      max-height:0;
      transition: max-height ease-in-out 3s;
    }
    input[name='opener'] {
      position: absolute;
      width: 100%;
      height: 68px;
      z-index: 1;
      opacity: 0;
      cursor: pointer;
      &:checked {
        & ~ .debitorTitle {
          background-image: url(../img/arrow-down-active.svg);
          background-repeat: no-repeat;
          background-position: 97%;
        }
        & ~ .debitorContent {
          opacity: 1;
          height: auto;
          max-height:1500px;
        }
      }
    }
  }
}