.kunden {

  text-decoration: none;
  justify-content: center;
  display: flex;
  flex-flow: row wrap;
  margin-right: -15px;
  margin-left: -15px;

  .kunde {
    position: relative;

    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, .125);
    border-radius: .25rem;

    margin-right: 15px;
    margin-left: 15px;
    flex: 1 0 auto;
    max-width: 300px;
    width: 300px;
    display: flex;
    transition: all .4s ease;
    box-shadow: 0 .28571rem .57143rem 0 rgba(0, 0, 0, .16);
    color: #000;
    margin-bottom: 40px;

    &:hover {
      text-decoration: none;
      color: #000;
      transform: translateY(-5px);
    }

    .kunde-body {
      flex: 1 1 auto;
      min-height: 1px;
      padding: 1.25rem;
      padding-bottom: 0;

      .kunde-title {
        font: 700 20px/24px Ubuntu;
        text-align: left;
        letter-spacing: 0;
        color: #000;
        margin-bottom: .75rem;
        margin-top: 0;
      }

      .button {
        margin-bottom: 15px;
      }
    }
  }
}

.userFilter {
  overflow: auto;
  margin-left: 15px;
  margin-right: 15px;
  a {
    margin-left: 15px;
  }
}

.userFilterBody {
  margin-top: 15px;
  position: relative;
  height: 0;
  z-index: -1;
  transition: all 1s ease-in-out;
  overflow: hidden;
  &.active {
    height: auto;
    z-index: 1;
  }

  .row {
    @media screen and (min-width:670px) {
      display: flex;
    }
    margin-left: 0;
    .col-md {
      flex-basis: 0;
      flex-grow: 1;
      max-width: 100%;
      position: relative;
      width: 100%;
      padding-right: 15px;
      padding-left: 15px;

      .form-group {
        margin-bottom: 1.5rem;
      }
      .form-control {
        display: block;
        width: 100%;
        padding: 8px;
        height: 40px;
        border: 1px solid #ccc;
        border-radius: 5px !important;
      }
    }
  }
}