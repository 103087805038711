.vertragswechselBS {

	// Produktauswahl

	.card {
		padding: 0;
		border-radius: 8px;
		margin-bottom: 1.5rem;
		.card-body {
			padding: 1.5rem 2rem 2rem;
		}
		&.low {
			.card-body {
				padding-bottom: 1rem;
			}
		}
		.slider-item {
			margin-bottom:1rem;
		}
		&.product-card {
			padding:0;
			margin-bottom: 0;
			height: 100%;
			.card-body {
				padding:0;
				margin:0;
			}
			label {
				padding: 1rem 3.5rem 2rem;
				border: solid 1px $input;
				border-radius: 8px;
				height: 100%;
				&:after {
					right:1rem;
					left:auto;
					top:1rem;
				}
				&:before {
					right:1.5rem;
					left:auto;
					top:1.5rem;
				}
			}
			input {
				&:checked {
					& + label {
						border-color: $cta;
						&:after {
							border-color:$cta;
						}
						&:before {
							background-color: $cta;
						}
					}
				}
			}
			.link-row {
				display:flex;
				margin-top: 1rem;
				a {
					color:$cta;
					text-decoration: none;
					display:inline-flex;
					align-items: center;
					margin-right: 1.5rem;
					font-weight: 400;
					i {
						margin-right: .5rem;
					}
					&:hover {
						text-decoration: underline;
					}
				}
			}
		}
		.card-star-footer {
			color:$text-muted;
			display:flex;
			span {
				margin-right:1.5rem;
				&:last-child {
					margin-right: 0;
				}
			}
		}
	}
	.button-row {
		display:flex;
		justify-content: flex-start;
		flex-wrap: wrap;
		margin-bottom: -1rem;
		.btn {
			margin-bottom: 1rem;
			white-space: nowrap;
		}
		.text-right {
			text-align:right;
		}
		&.high {
			margin-bottom:.5rem;
		}
	}
	sup {
		font-size: .625rem;
	}
	@include media-breakpoint-down(md) {
		.card {
			.card-body {
				padding: 1rem;

				.productList {
					li {
						display: block;

						&.topline {
							padding-right: 2.4rem;
							.label {
								display: flex;
								align-items: flex-start;
							}
							.icon {
								position: relative;
								left: 0;
							}
						}

						.label {
							padding-bottom: .4rem;

							.icon {
								margin-right: .5rem;
								flex: 0 0 auto;
							}

							@include media-breakpoint-up(lg) {
								.icon {
									margin-right: 1rem;
								}
							}
						}

						.value {
							text-align: right;
						}
					}
				}
			}
			.card-star-footer {
				display:block;
				span {
					display: block;
				}
			}
			&.product-card {
				label {
					padding:1rem;
				}
				.link-row {
					display:block;
					a {
						display: inline-block;
						margin-bottom: .5rem;
					}
				}
			}
			.slider-products {
				margin:0 -.5rem;
				.slick-list {
					overflow: initial;
					padding-right: 1rem;
					.slick-track {
						display:flex;
					}
					.slick-slide {
						padding: 0 .5rem;
						height:auto;
					}
				}
				.slick-dots {
					margin-top:0
				}
			}
		}
		.aside {
			margin-top: 2.5rem;
		}
	}
	// // Produktauswahl
	.dataList {
		margin-bottom: 1.5rem;
		@include media-breakpoint-down(md) {
			li {
				display:block;
				.label {
					padding-bottom: .4rem;
				}
			}
		}
	}
	.modal-inner {
		img {
			max-width: 100%;
		}
	}

	// Overview
	.summary-wrapper {
		margin-bottom: 2rem;
	}
}