.iconlist {
	margin: 0;
	padding: 0;

	li {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: .5rem 0;

		.label {
			display: flex;
			align-items: center;

			.icon {
				margin-right: .5rem;
				flex: 0 0 auto;
			}

			@include media-breakpoint-up(lg) {
				.icon {
					margin-right: 1rem;
				}
			}
		}

		&:last-child {
			padding-bottom: 0;
		}

		.value {
			font-weight: 700;
			text-align: right;
		}
	}
}

.productList {
	margin: 0;
	padding: 0;
	li {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: .4rem 0;
		color: $black;
		font-weight: 400;
		border-bottom:1px solid $input;
		&.topline {
			font-weight:700;
			border:none;
			padding-bottom:.5rem;
			position: relative;
			.icon {
				position:absolute;
				left:-2.5rem;
				margin:0;
			}
		}
		.label {
			display: flex;
			align-items: center;
		}
		.value {
			text-align: right;
		}
	}
}

.quicklinks {
	margin: 0;
	padding: 0;
	list-style-type: none;

	li {
		padding-bottom: 1rem;

		a {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 1rem 1.5rem;
			background: $lightgrey;
			border-radius: .5rem;
			font-weight: 700;
		}


		.label {
			display: flex;
			align-items: center;

			.icon {
				margin-right: 1rem;
			}
		}

		&:last-child {
			padding-bottom: 0;
		}
	}
}
.contactlist {
	margin: 0;
	padding: 0;
	list-style-type: none;
	li {
		color:$cta;
		display:flex;
		align-items: center;
		justify-content: flex-start;
		padding: .25rem 0;
		.icon {
			margin-right: .5rem;
		}
	}
}

.dataList {
	margin: 0;
	padding: 0;
	list-style-type: none;
	li {
		display: flex;
		align-items: center;
		padding: .4rem 0;
		color: $black;
		font-weight: 400;
		border-bottom:1px solid $input;
		.label, .value {
			flex:0 0 50%;

		}
	}
}